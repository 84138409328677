import React, { useEffect } from "react";
import { getNextEvent, getTimeSuffix } from "./events";
import "./App.css";

function App() {
  const [day, setDay] = React.useState();
  const [time, setTime] = React.useState();
  const [nextEvent, setNextEvent] = React.useState();

  useEffect(() => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const timer = setInterval(() => {
      var today = new Date();

      setDay(days[today.getDay()]);
      setTime(
        (today.getHours() % 12 || 12) +
          ":" +
          today.getMinutes().toString().padStart(2, "0") +
          getTimeSuffix(today)
      );
      setNextEvent(getNextEvent(today));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="App">
      {day && <h1>Today is {day}</h1>}
      {time && <h2>The Time is {time} </h2>}

      {nextEvent && nextEvent.image && (
        <div>
          <img
            alt="person"
            height={300}
            width={300}
            src={nextEvent.image}
          ></img>
        </div>
      )}
      {nextEvent && nextEvent.name ? (
        <h3>{nextEvent.name}</h3>
      ) : (
        <h3>Just Relax</h3>
      )}
    </div>
  );
}

export default App;
