const events = [
  //SUNDAY
  [
    {
      time: "8:00",
      showUntil: "8:30",
      event: "Helen will be here to give you your meds",
    },
    {
      time: "11:00",
      showUntil: "11:30",
      event: "David will pick you up for sunday lunch",
    },
    {
      time: "19:00",
      showUntil: "19:30",
      event:
        "David will pick you up and take you to the dance. Don't get ready until 18:00",
    },
  ],
  //MONDAY
  [
    {
      time: "7:30",
      showUntil: "7:45",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your breakfast",
    },
    {
      time: "8:00",
      showUntil: "8:30",
      event: "Donna will be here to give you your meds",
    },
    {
      time: "11:30",
      showUntil: "12:00",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your lunch",
    },
    {
      time: "15:30",
      showUntil: "16:00",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your tea",
    },
    {
      time: "18:00",
      showUntil: "18:30",
      event: "Donna will be here to give you your meds",
    },
  ],
  //TUESDAY
  [
    {
      time: "7:30",
      showUntil: "7:40",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your breakfast",
    },
    {
      time: "8:00",
      showUntil: "8:30",
      event: "Donna will be here to give you your meds",
    },
    {
      time: "9:30",
      showUntil: "10:00",
      event: "Sarah will be here to do your hair",
    },
    {
      time: "11:30",
      showUntil: "12:00",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your lunch",
    },
    {
      time: "15:30",
      showUntil: "16:00",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your tea",
    },
    {
      time: "18:00",
      showUntil: "18:30",
      event: "Donna will be here to give you your meds",
    },
  ],
  //WEDNESDAY
  [
    {
      time: "7:30",
      showUntil: "8:00",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your breakfast",
    },
    {
      time: "8:00",
      showUntil: "8:30",
      event: "Helen will be here to give you your meds",
    },
    {
      time: "11:30",
      showUntil: "12:00",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your lunch",
    },
    {
      time: "15:30",
      showUntil: "16:00",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your tea",
    },
    {
      time: "18:00",
      showUntil: "18:30",
      event: "Helen will be here to give you your meds",
    },
  ],
  //THURSDAY
  [
    {
      time: "7:30",
      showUntil: "7:30",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your breakfast",
    },
    {
      time: "8:00",
      showUntil: "8:30",
      event: "Helen will be here to give you meds",
    },
    {
      time: "11:30",
      showUntil: "12:00",
      event: "David will be here to make your lunch",
    },
    {
      time: "15:30",
      showUntil: "16:00",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your tea",
    },
    {
      time: "18:00",
      showUntil: "18:10",
      image: "./lisa.jpg",
      event: "Helen will be here to give you your meds",
    },
  ],
  //FRIDAY
  [
    {
      time: "7:30",
      showUntil: "8:00",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your breakfast",
    },
    {
      time: "8:00",
      showUntil: "8:30",
      event: "Helen will be here to give you your meds",
    },
    {
      time: "11:30",
      showUntil: "12:00",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your lunch",
    },
    {
      time: "15:30",
      showUntil: "16:00",
      image: "./lisa.jpg",
      event: "Lisa will be here to make your tea",
    },
    {
      time: "18:00",
      showUntil: "18:30",
      event: "Helen will be here to give you your meds",
    },
  ],
  //SATURDAY
  [
    {
      time: "8:00",
      showUntil: "8:30",
      event:
        "Can you make your own breakfast. Gluten Free For Joan, Normal for Alan. Helen will be here to give you your meds",
    },
    {
      time: "12:00",
      showUntil: "12:30",
      event: "David is just playing golf. He will be here to make your lunch",
    },
    {
      time: "17:00",
      showUntil: "17:10",
      event: "David will be here to make your tea",
    },
    {
      time: "18:00",
      showUntil: "18:30",
      event: "Helen will be here to give you your meds",
    },
  ],
];

export const getNextEvent = (today) => {
  const eventToShow = events[today.getDay()].find((event) => {
    return (
      new Date("1/1/1999 " + (event.showUntil || event.time) + ":00") >
      new Date(
        "1/1/1999 " + today.getHours() + ":" + today.getMinutes() + ":00"
      )
    );
  });
  if (eventToShow) {
    const tempTime = new Date("1/1/1999 " + eventToShow.time + ":00");
    return {
      name: `${eventToShow.event} at about ${
        (tempTime.getHours() % 12 || 12) +
        ":" +
        tempTime.getMinutes().toString().padStart(2, "0") +
        getTimeSuffix(tempTime)
      }`,
      image: eventToShow.image,
    };
  } else {
    return null;
  }
};

export function getTimeSuffix(today) {
  var msg;

  var currentHour = today.getHours();
  if (currentHour < 12) msg = " in the Morning";
  else if (currentHour === 12) msg = "Midday";
  else if (currentHour >= 12 && currentHour <= 17) msg = " in the Afternoon";
  else if (currentHour >= 17 && currentHour <= 24) msg = " in the Evening";

  return msg;
}

export default events;
